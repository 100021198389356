.c-wrapper {
    width: 10rem;
    height: 13.7rem;
    position: absolute;
    display: flex;
    flex-direction: column;    
    text-align: center;
    background: rgba(255, 255, 255, 0.26);
    border: 7px solid var(--orangeCard);
    border-radius: 20px;
    padding: 0px 26px 2rem 26px;
    
 
}

.c-wrapper>img {
    transform: scale(0.4);
    margin-bottom: -3rem;
   
}
.c-wrapper>:nth-child(2) {
    color: rgb(84, 8, 8);
    font-weight: 600;
}
.c-wrapper>:nth-child(3) {
    color: rgb(95, 124, 143);
    font-weight: 400;
}

@media screen and (max-width: 480px){
    .card{
      width: auto;
    }
  }